<template>
    <div class="cbox">

        <div class="bcontent">
            <div style="height: 100%;">
                <el-row type="flex" justify="start" style="height: 100%;">
                            <div class="leftarea">
                                <div style="margin-bottom: 10px">
                                    <span @click="getAll" style="margin-left: 23px; font-size: 17px; color: #409eff">
                                        所属类别
                                    </span>
                                    <el-button type="primary" style="
							  background-color: burlywood;
							  border-color: burlywood;
							  margin-left: 8%;
							  margin-top: 10px;
							" @click="formAddType">新增</el-button>

                                </div>
                                <el-tree :data="dataTree" @node-click="handleNodeClick" style="margin-top: 20px">
                                    <span class="custom-tree-node" slot-scope="{ node, data }">
                                        <span>{{ node.label }} <span
                                                style="font-size: 12px; color: #999999; line-height: 12px;">
                                                {{ node.data.group_type == 'tch' ? '教师' : '学生' }}
                                                {{ node.data.ispub == 1 ? '公共' : '' }}</span></span>
                                        <span>
                                            <el-button type="text" size="mini" @click="() => updateType(data)">
                                                修改
                                            </el-button>
                                            <el-button type="text" size="mini" @click="() => removeType(node, data)">
                                                删除
                                            </el-button>
                                        </span>
                                    </span>
                                </el-tree>
                            </div>
                            <div class="aa" style="border: solid 1px #ccc"></div>
                            <div class="rightarea">
                                模块名称：
                                <el-input placeholder="请输入模块名称" prefix-icon="el-icon-search" v-model="select_m_name"
                                    style="width: 150px; margin-right: 20px; margin-bottom: 25px">
                                    >
                                </el-input>
                                模块类型：
                                <el-select v-model="select_m_type" placeholder="模块类型:"
                                    style="width: 150px; margin-right: 20px">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="PC" value="PC"></el-option>
                                    <el-option label="APP" value="APP"></el-option>
                                </el-select>
                                <el-button type="primary" @click="getAll" style="margin-right: 20px">查询</el-button>
                                <el-button type="primary" @click="formAdd"
                                    style="background-color: burlywood; border-color: burlywood">新增</el-button>


                                <el-button v-if="false" type="primary" @click="() => { showicon = true }"
                                    style="background-color: burlywood; border-color: burlywood">showicon</el-button>




                                <template>
                                    <el-table v-tableFit :data="tableData" height="calc(100% - 70px)"
                                        style="width: 100%;" row-key="id" border stripe default-expand-all :tree-props="{
                        children: 'children',
                        hasChildren: 'hasChildren',
                    }">
                                        <el-table-column type="index" label="序号" width="80px"></el-table-column>
                                        <el-table-column prop="icon" label="模块图标" width="100">
                                            <template slot-scope="scope">
                                                <img v-if="scope.row.icon" :src="scope.row.icon" class="avatar"
                                                    style="width: 50px; height: 50px" />
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="m_name" label="模块名称" width="140px"></el-table-column>
                                        <el-table-column prop="group_name" label="所属类别" width="140px"></el-table-column>
                                        <el-table-column prop="m_type" width="100px" label="模块类型"></el-table-column>
                                        <el-table-column label="启用状态" prop="status" width="100">
                                            <template slot-scope="scope">
                                                <p v-if="scope.row.status === 0" style="color: red">
                                                    未启用
                                                </p>
                                                <p v-if="scope.row.status === 1" style="color: #67c23a">
                                                    启用
                                                </p>
                                            </template>
                                        </el-table-column>
                                        <el-table-column prop="url" width="400px" label="URL地址"></el-table-column>
                                        <el-table-column prop="sort" width="80px" label="排序号"></el-table-column>
                                        <el-table-column fixed="right" label="操作" width="120" align="center">
                                            <template slot-scope="scope">
                                                <el-popconfirm width="100px" confirmButtonText="确定"
                                                    cancelButtonText="取消" icon="el-icon-info" iconColor="red"
                                                    @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
                                                    <el-tag slot="reference" class="pointer" type="danger"
                                                        size="mini">删除</el-tag>
                                                </el-popconfirm>
                                                <el-tag class="pointer ml10" type="primary" size="mini"
                                                    @click="handleModify(scope.row)">修改</el-tag>
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                </template>
                            </div>
                </el-row>
                <el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false"
                    :destroy-on-close="true" custom-class="cus_dialog" width="800px">
                    <div style="width:100%">
                        <el-form :model="formData" :rules="rules" ref="ruleForm" label-width="100px"
                            style="max-height: 450px; overflow-y: auto; overflow-x: hidden; min-height: 300px">
                            <el-row :gutter="20">
                                <el-col :span="8">
                                    <el-form-item label="模块名称" prop="m_name">
                                        <el-input v-model.trim="formData.m_name" style="width:100%;" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="所属类别:" prop="gid">
                                        <el-select v-model="formData.gid" placeholder="所属类别" style="width:100%;">
                                            <el-option v-for="item in selectData" :key="item.id"
                                                :label="item.group_name" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="模块类型" prop="m_type">
                                        <el-select v-model="formData.m_type" placeholder="模块类型" style="width:100%;">
                                            <el-option label="PC" value="PC"></el-option>
                                            <el-option label="APP" value="APP"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="24">
                                    <el-form-item label="URL地址" prop="url">
                                        <el-input v-model.trim="formData.url" style="width: 100%" />
                                    </el-form-item>
                                </el-col>

                            </el-row>

                            <el-row :gutter="20">
                                <el-col :span="12">
                                    <el-form-item label="启用状态:" prop="status">
                                        <el-radio-group size="small" v-model="formData.status" style="width:100%;">
                                            <el-radio :label="0">未启用</el-radio>
                                            <el-radio :label="1">启用</el-radio>
                                        </el-radio-group>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="12">
                                    <el-form-item label="排序号">
                                        <el-input-number v-model="formData.sort" :min="1" :max="1000" label="排序号"
                                            style="width:100%;">
                                        </el-input-number>
                                    </el-form-item>
                                </el-col>
                            </el-row>


                            <el-form-item label="模块图标" prop="icon">
                                <el-upload style="display: inline-block; margin-right: 20px;border:1px dashed #c0c4cc;"
                                    action="" :http-request="uploadOss" :on-success="uploadFiles"
                                    :show-file-list="false" name="image">
                                    <img v-if="formData.icon" :src="formData.icon" class="avatar" />
                                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                                <el-input v-model.trim="formData.icon" style="width: 100%;margin-top:10px;"
                                    placeholder="这里可以输入图标url" />
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-row slot="footer">
                        <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
                        <el-button size="small" type="primary" @click="submitForm('ruleForm')">保存</el-button>
                    </el-row>
                </el-dialog>
                <el-dialog :title="modalTypeTitle" :visible.sync="typeIsShowModal" :close-on-click-modal="false"
                    :destroy-on-close="true" style="padding: 30px 100px" custom-class="cus_dialog">
                    <el-form :model="formTypeData" ref="ruleTypeForm" :rules="typeRules" label-width="100px"
                        style="max-height: 450px; overflow-y: auto; min-height: 300px">
                        <div style="font-size: 12px; color: orangered; padding-bottom: 30px;">
                            提示：如果要设置该模块为所有人未登录的人都可以看到，请选择适用对象为【学生】，是否公共设置为【是】<br />
                            <span style="display: inline-block; width: 33px; height: 16px;"></span>
                            如果要设置该模块为所有教师都可以看到，请选择适用对象为【教师】，是否公共设置为【是】<br />
                            <span style="display: inline-block; width: 33px; height: 16px;"></span>
                            如果要设置该模块为所有登录的学生都可以看到，请选择适用对象为【学生】，是否公共设置为【否】<br />
                            <span style="display: inline-block; width: 33px; height: 16px;"></span>
                            注：适用对象为【学生】，公共设置为【是】的模块，教师也可以看的到<br />

                        </div>
                        <el-form-item label="适用对象">
                            <el-select v-model="formTypeData.group_type">
                                <el-option value="stu" label="学生"></el-option>
                                <el-option value="tch" label="老师"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="是否公共">
                            <el-select v-model="formTypeData.ispub">
                                <el-option :value="0" label="否"></el-option>
                                <el-option :value="1" label="是"></el-option>
                            </el-select>
                            <el-tooltip content="对象为学生的公共模块在不登录的状态也可以显示;对象为教师的公共模块所有登录的老师都可以显示" placement="top"
                                effect="light">
                                <i class="el-icon-question" style="font-size: 20px; margin-left: 20px;"></i>
                            </el-tooltip>

                        </el-form-item>
                        <el-form-item label="类型名称" prop="group_name">
                            <el-input v-model.trim="formTypeData.group_name" style="width: 50%" />
                        </el-form-item>
                        <el-form-item label="排序号">
                            <el-input-number v-model="formTypeData.sort" :min="1" :max="1000" label="排序号"
                                style="width: 50%">
                            </el-input-number>
                        </el-form-item>
                    </el-form>
                    <el-row>
                        <el-col :span="22" style="text-align: right">
                            <el-button size="small" type="default" @click="typeIsShowModal = false">取消</el-button>
                            <el-button size="small" type="primary" @click="submitTypeForm('ruleTypeForm')">保存
                            </el-button>
                        </el-col>
                    </el-row>
                </el-dialog>
            </div>
        </div>





        <el-dialog title="预览图标" :visible.sync="showicon" :close-on-click-modal="false" :destroy-on-close="true"
            custom-class="cus_dialog" width="1400px">
            <div class="flexStart" style="width:100%;flex-wrap:wrap;">
                <div class="" style="width:100px;margin-bottom:20px;" v-for="(item, index) in tableData">

                    <img v-if="item.icon" :src="item.icon" class="avatar" style="width: 50px; height: 50px" />
                    <div class="">{{ item.m_name }}<br />({{ item.group_name }})</div>
                </div>

            </div>

        </el-dialog>


    </div>
</template>
<script>
import api from "@/api/api";
export default {
    components: {
    },
    data() {
        return {
            showicon: false,
            select_m_name: "",
            select_m_type: "",
            dataTree: [],
            modalTitle: "添加模块",
            modalTypeTitle: "添加所属类别",
            selectData: [],
            isShowModal: false,
            typeIsShowModal: false,
            formData: {
                icon: ""
            },
            formTypeData: {},
         
            tableData: [],
            activeName: "CN",
            typeRules: {
                group_name: [{
                    required: true,
                    message: "请输入类型名称",
                    trigger: "blur",
                },],
                sort: [{
                    required: true,
                    message: "请填写排序号",
                    trigger: "blur",
                },],
            },
            rules: {
                m_name: [{
                    required: true,
                    message: "请输入模块名称",
                    trigger: "blur",
                },],

                gid: [{
                    required: true,
                    message: "请选择所属类别",
                    trigger: "blur",
                },],
                url: [{
                    required: true,
                    message: "请输入URL地址",
                    trigger: "blur",
                },],
                icon: [{
                    required: true,
                    message: "请上传模块图标",
                    trigger: "blur",
                },],
                m_type: [{
                    required: true,
                    message: "请填写模块类型",
                    trigger: "blur",
                },],
                sort: [{
                    required: true,
                    message: "请填写排序号",
                    trigger: "blur",
                },],
            },
            firstCascaderPropsArea: {
                label: "label",
                value: "code",
                children: "children",
                emitPath: false,
            },
            schoolList: [],
            areaScore: {
                score_line: -1,
                plan_score: 0,
            },

            treeData: [],
            firstCascaderProps: {
                label: "dept_name",
                value: "id",
                children: "children",
                emitPath: false,
                multiple: true,
                checkStrictly: true,
            },
        };
    },
    mounted() {
        this.getList();
        this.getAll();
    },
    methods: {
        handelTab() {

        },
        getAll() {
            this.$http
                .post("/api/by_sys_module_all", {
                    m_type: this.select_m_type,
                    m_name: this.select_m_name,
                    sys_type:'bygz'
                })
                .then((res) => {
                    this.tableData = res.data;
                });
        },
        uploadFiles(e) {
            this.formData.icon = e.src;
        },
        updateType(data) {
            this.typeIsShowModal = true;
            this.formTypeData = data;
            this.modalTypeTitle = "修改所属类别";
        },
        removeType(node, row) {
            this.$http
                .post("/api/by_module_group_delete", {
                    id: row.id,
                })
                .then((res) => {
                    this.$message.success("删除成功");
                    this.getList();
                });
        },
        handleNodeClick(data) {
            this.tableData = data.child;
        },
        formAdd() {
            this.isShowModal = true;
            this.formData = {
                icon: "",
                status: 1,
                sort: 1
            };
            this.modalTitle = "添加模块";
        },
        formAddType() {
            this.typeIsShowModal = true;
            this.formTypeData = {
                sort: 1
            };
            this.modalTypeTitle = "添加所属类别";
        },
        getList() {
            this.$http.post("/api/by_sys_module_list", { sys_type: 'bygz' }).then((res) => {
                this.selectData = res.data;
                for (let tree of res.data) {
                    tree.label = tree.group_name || '-' + "  (共 " + (tree.count || '-') + " 个模块) ";
                    //  this.tableData = tree.modules;
                    tree.child = tree.children;
                    delete tree.children;
                }
                this.dataTree = res.data;
                this.getAll();
            });
        },
        handleDel(row) {
            this.$http
                .post("/api/by_module_delete", {
                    id: row.id,
                })
                .then((res) => {
                    this.$message.success("删除成功");
                    this.getList();
                });
        },
        handleModify(row) {
            this.isShowModal = true;
            this.modalTitle = "修改模块";
            this.formData = row;
        },

        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.formData.sys_type = 'bygz'
                    this.$http.post("/api/by_sys_module_edit", this.formData).then((res) => {
                        this.$message.success("保存成功");
                        this.getList();
                        this.isShowModal = false;
                        return;
                    });
                }
            });
        },
        submitTypeForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.formTypeData.sys_type = 'bygz'
                    this.$http.post("/api/by_sys_module_group_edit", this.formTypeData).then((res) => {
                        this.$message.success("保存成功");
                        this.getList();
                        this.typeIsShowModal = false;
                        return;
                    });
                }
            });
        },
        showRoleModal() {
            this.$router.push("/mrole")
        }
    },
};
</script>
<style>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.leftarea {
    float: left;
    width: 20%;
    height: 100%;
    margin-right: 20px;
}

.aa {
    float: left;
    width: 0%;
    height: 100%;

}

.rightarea {
    float: left;
    width: 75%;
    height: 100%;
    margin-left: 30px;
    overflow: auto;
    margin-top: 10px;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409eff;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
<style type="text/css">
.el-tabs__content {
    height: calc(100% - 70px) !important;
}

.el-tab-pane {
    height: 100%;
}
</style>
